import {
	Button,
	Divider,
	Grid,
	Hidden,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Theme,
	Typography,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {
	BreadcrumbItem,
	Breadcrumbs,
	ContainerLayout,
	IntegerNumberFormat,
	NumberField,
	SaveIcon,
	Tabs,
} from "@variocube/app-ui";
import * as React from "react";
import {createElement, Fragment, useCallback, useState} from "react";
import {useAsync} from "react-async-hook";
import {useNavigate} from "react-router";
import {Site} from "../../domain/Site";
import {SitesProvider} from "../../domain/SitesProvider";
import {TenantsProvider} from "../../domain/TenantsProvider";
import {TenantTemplateProvider} from "../../domain/TenantTemplateProvider";
import {
	CsvColumnMapping,
	CsvCubeMapping, DeliveryConfig,
	NotificationTemplate,
	RecipientRequired, ReturnDeliveryConfig,
	TagFragment,
	TagFragmentSeverity,
	TemplateType,
	Tenant,
	TenantManualHandoverOptions,
	TenantNotificationSetting,
	TenantUpdateSettingsRequest,
	UseExistingBox,
} from "../../domain/TenantUser";
import {TenantUserProvider} from "../../domain/TenantUserProvider";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {shorten} from "../../tools";
import {BoxedPaper} from "../BoxedPaper";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {LocalesSelection} from "../inputs/LocalesSelection";
import {Loading} from "../Loading";
import {SimpleCheckBox} from "../SimpleCheckBox";
import {SimpleTextInput} from "../SimpleTextInput";
import {SnackbarMessage, StateSnackbar} from "../StateSnackbar";
import {useTenantId} from "../TenantContextProvider";
import {TenantManualHandoverOptionsInput} from "../TenantManualHandoverOptionsInput";
import {TenantNotificationSettingInput} from "../TenantNotificationSettingInput";
import {AddTagFragmentDialog} from "./AddTagFragmentDialog";
import {AddTemplateDialog} from "./AddTemplateDialog";
import {EditTagFragmentDialog} from "./EditTagFragmentDialog";
import {EditTemplateDialog} from "./EditTemplateDialog";
import {HeaderWithHelpKey} from "./HeaderWithHelpKey";
import {OAuth2EmailSenderSetting} from "./OAuth2EmailSenderSetting";
import {IPrintViewConfig, PrintViewConfig} from "./PrintViewConfig";
import {RecipientRequiredInput} from "./RecipientRequiredInput";
import {Sites} from "./Sites";
import {TenantSettingsCsvImportEdit} from "./TennatSettingsCsvImportEdit";
import TenantDeliveryConfigSetting from "./TenantDeliveryConfigSetting";
import TenantReturnDeliveryConfigSetting from "./TenantReturnDeliveryConfigSetting";

export function TenantSettingsEdit({isBreadcrumbVisible = false}: { isBreadcrumbVisible?: boolean }) {
	const {t, e, language} = useLocalization();
	const navigate = useNavigate();
	const tenantId = useTenantId();

	const [tenant, setTenant] = useState<Tenant>();
	const [refreshing, setRefreshing] = useState<boolean>(false);
	const [dirty, setDirty] = useState<boolean>(false);

	const [tab, setTab] = useState("overview");

	/**
	 * Request properties
	 */
	const [name, setName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [phone, setPhone] = useState<string>("");
	const [locales, setLocales] = useState<string>("en");
	const [notifications, setNotifications] = useState<TenantNotificationSetting>(TenantNotificationSetting.All);
	const [tagFragments, setTagFragments] = useState<TagFragment[]>([]);

	const [emailSender, setEmailSender] = useState<string>("");
	const [emailReplyTo, setEmailReplyTo] = useState<string>("");
	const [smtpHost, setSmtpHost] = useState<string>("");
	const [smtpPort, setSmtpPort] = useState<number | null>(null);
	const [smtpUser, setSmtpUser] = useState<string>("");
	const [smtpPassword, setSmtpPassword] = useState<string>("");

	const [splitStorageAllowed, setSplitStorageAllowed] = useState<boolean>(false);
	const [partialPickupAllowed, setPartialPickupAllowed] = useState<boolean>(false);
	const [recipientCollectionEnabled, setRecipientCollectionEnabled] = useState(false);

	const [gracePeriod, setGracePeriod] = useState<number>(0);

	const [nfcCardLearningEnabled, setNfcCardLearningEnabled] = useState<boolean>(false);
	const [photoInboxEnabled, setPhotoInboxEnabled] = useState<boolean>(false);
	const [checkAfterPickupRequired, setCheckAfterPickupRequired] = useState<boolean>(false);
	const [deliveryCreationFromCodeEnabled, setDeliveryCreationFromCodeEnabled] = useState<boolean>(false);
	const [deliveryCreationForDepartmentEnabled, setDeliveryCreationForDepartmentEnabled] = useState(false);

	const [reminderHours, setReminderHours] = useState<number>(0);
	const [retentionDays, setRetentionDays] = useState<number>(0);
	const [retentionDaysCreated, setRetentionDaysCreated] = React.useState<number>(0);
	const [maxStorageTime, setMaxStorageTime] = useState<number>(0);
	const [storageTimeConsidersWeekends, setStorageTimeConsidersWeekends] = useState<boolean>(false);

	const [scheduleAccessCodeRegenerationEnabled, setScheduleAccessCodeRegenerationEnabled] = useState<boolean>(false);

	const [recipientRequired, setRecipientRequired] = useState<RecipientRequired>(RecipientRequired.None);

	const [printViewConfig, setPrintViewConfig] = useState<IPrintViewConfig>(PrintViewConfig.defaultConfig);
	const [printViewConfigLarge, setPrintViewConfigLarge] = useState<IPrintViewConfig>(PrintViewConfig.defaultConfig);

	const [csvColumnMapping, setCsvColumnMapping] = useState<CsvColumnMapping[]>([]);
	const [csvCubeMapping, setCsvCubeMapping] = useState<CsvCubeMapping[]>([]);
	const [csvFirstLineIgnored, setCsvFirstLineIgnored] = useState<boolean>(false);
	const [csvCharset, setCsvCharset] = useState<string>("");

	const [mapToManualHandoverEnabled, setMapToManualHandoverEnabled] = useState<boolean>(false);
	const [manualHandoverOptions, setManualHandoverOptions] = useState<TenantManualHandoverOptions>(
		TenantManualHandoverOptions.None,
	);

	const [ocrEnabled, setOcrEnabled] = useState<boolean>(false);
	const [photoEnabled, setPhotoEnabled] = useState<boolean>(true);
	const [recipientPickupKeyExposed, setRecipientPickupKeyExposed] = useState<boolean>(false);
	const [pickupCodeExposed, setPickupCodeExposed] = useState(false);
	const [recipientsNotifyingSpecificationRequired, setRecipientsNotifyingSpecificationRequired] = useState<boolean>(
		false,
	);

	const [deliveryConfig, setDeliveryConfig] = useState<DeliveryConfig>({ enabled: false });
	const [returnDeliveryConfig, setReturnDeliveryConfig] = useState<ReturnDeliveryConfig>({ enabled: false, returnRequiresAuthorization: false });
	const [useExistingBox, setUseExistingBox] = useState<UseExistingBox>(UseExistingBox.AskToAdd);

	const [faqHelpUrl, setFaqHelpUrl] = useState<string>("");

	const [ocrWidth, setOcrWidth] = useState<number | null>(null);
	const [ocrHeight, setOcrHeight] = useState<number | null>(null);

	const [autoTaggingEnabled, setAutoTaggingEnabled] = useState<boolean>(false);

	/**
	 * Front-end shenanigan
	 */
	const [notificationTemplates, setNotificationTemplates] = useState<NotificationTemplate[]>([]);
	const [addTemplate, setAddTemplate] = useState<boolean>(false);
	const [editingTemplate, setEditingTemplate] = useState<number | undefined>();

	const [addTagFragment, setAddTagFragment] = useState<boolean>(false);
	const [editingTagFragment, setEditingTagFragment] = useState<number | undefined>();

	const [isAddingTemplate, setIsAddingTemplate] = useState<boolean>(true);

	const [loading, setLoading] = useState<boolean>(false);

	const [stateSnackbarMessage, setStateSnackbarMessage] = React.useState<SnackbarMessage | undefined>(undefined);

	const [sites, setSites] = useState<Site[]>([]);

	const classes = useStyles();

	useAsync(async () => {
		loadSettings();
	}, [tenantId]);

	React.useEffect(() => {
		setDirty(true);
	}, [
		name,
		email,
		phone,
		locales,
		notifications,
		tagFragments,
		emailSender,
		emailReplyTo,
		splitStorageAllowed,
		partialPickupAllowed,
		recipientCollectionEnabled,
		pickupCodeExposed,
		deliveryCreationForDepartmentEnabled,
		scheduleAccessCodeRegenerationEnabled,
		gracePeriod,
		nfcCardLearningEnabled,
		photoInboxEnabled,
		checkAfterPickupRequired,
		deliveryCreationFromCodeEnabled,
		reminderHours,
		retentionDays,
		retentionDaysCreated,
		maxStorageTime,
		smtpHost,
		smtpPort,
		smtpUser,
		smtpPassword,
		recipientRequired,
		printViewConfig,
		printViewConfigLarge,
		deliveryConfig,
		returnDeliveryConfig,
		mapToManualHandoverEnabled,
		manualHandoverOptions,
		ocrEnabled,
		photoEnabled,
		recipientPickupKeyExposed,
		recipientsNotifyingSpecificationRequired,
		useExistingBox,
		faqHelpUrl,
		ocrWidth,
		ocrHeight,
		autoTaggingEnabled,
		addTemplate,
		editingTemplate,
		addTagFragment,
		editingTagFragment,
		isAddingTemplate,
		storageTimeConsidersWeekends
	]);

	let isNumbersInputNull = React.useMemo(() => {
		return gracePeriod == null || reminderHours == null || retentionDays == null || maxStorageTime == null;
	}, [gracePeriod, reminderHours, retentionDays, maxStorageTime]);

	async function loadSettings() {
		setSites(await SitesProvider.list(tenantId));

		const tenant = await TenantsProvider.get(tenantId);
		const notificationTemplates = await TenantUserProvider.listNotificationTemplates(tenant.centerId);

		setTenant(tenant);
		setName(tenant.name);
		setEmail(tenant.email || "");
		setPhone(tenant.phone || "");
		setLocales(tenant.locales || "en");
		setNotifications(tenant.notifications || TenantNotificationSetting.All);
		setNotificationTemplates(sortNotifications(notificationTemplates));
		setTagFragments(tenant.tagFragments);
		setEmailSender(tenant.emailSender || "");
		setEmailReplyTo(tenant.emailReplyTo || "");
		setSplitStorageAllowed(tenant.splitStorageAllowed);
		setPartialPickupAllowed(tenant.partialPickupAllowed);
		setRecipientCollectionEnabled(tenant.recipientCollectionEnabled);
		setPickupCodeExposed(tenant.pickupCodeExposed);
		setDeliveryCreationForDepartmentEnabled(tenant.deliveryCreationForDepartmentEnabled);
		setGracePeriod(tenant.gracePeriod || 0);
		setNfcCardLearningEnabled(tenant.nfcCardLearningEnabled);
		setPhotoInboxEnabled(tenant.photoEnabled);
		setCheckAfterPickupRequired(tenant.checkAfterPickupRequired);
		setDeliveryCreationFromCodeEnabled(tenant.deliveryCreationFromCodeEnabled);
		setReminderHours(tenant.reminderHours);
		setRetentionDays(tenant.retentionDays);
		setRetentionDaysCreated(tenant.retentionDaysCreated);
		setMaxStorageTime(tenant.maxStorageTime);
		setStorageTimeConsidersWeekends(tenant.storageTimeConsidersWeekends);
		setScheduleAccessCodeRegenerationEnabled(tenant.scheduleAccessCodeRegenerationEnabled);
		setRecipientRequired(tenant.recipientRequired);
		setPrintViewConfig(PrintViewConfig.merge(tenant.printViewConfig));
		setPrintViewConfigLarge(PrintViewConfig.merge(tenant.printViewConfigLarge));

		if (tenant.deliveryConfig) setDeliveryConfig(tenant.deliveryConfig);
		if (tenant.returnDeliveryConfig) setReturnDeliveryConfig(tenant.returnDeliveryConfig);

		setSmtpHost(tenant.smtpHost || "");
		setSmtpPort(tenant.smtpPort || null);
		setSmtpUser(tenant.smtpUser || "");
		setSmtpPassword(tenant.smtpPassword || "");
		setMapToManualHandoverEnabled(tenant.mapToManualHandoverEnabled);
		setManualHandoverOptions(tenant.manualHandoverOptions);
		setOcrEnabled(tenant.ocrEnabled);
		setPhotoEnabled(tenant.photoEnabled);
		setRecipientPickupKeyExposed(tenant.recipientPickupKeyExposed);
		setRecipientsNotifyingSpecificationRequired(tenant.recipientsNotifyingSpecificationRequired);
		setUseExistingBox(tenant.useExistingBox);
		setFaqHelpUrl(tenant.faqHelpUrl || "");
		setOcrWidth(tenant.ocrWidth || null);
		setOcrHeight(tenant.ocrHeight || null);
		setAutoTaggingEnabled(tenant.autoTaggingEnabled);
		setCsvColumnMapping(tenant.csvColumnMapping);
		setCsvCubeMapping(tenant.csvCubeMapping);

		setCsvFirstLineIgnored(tenant.csvFirstLineIgnored);
		setCsvCharset(tenant.csvCharset ?? "");

		// Switch UI back to normal state
		setLoading(false);
		setRefreshing(false);
		setDirty(false);
	}

	async function handleSave() {
		if (tenant && tenantId) {
			setLoading(true);

			if (isNumbersInputNull) return;

			const request: TenantUpdateSettingsRequest = {
				name,
				email,
				phone,
				locales,
				notifications,
				tagFragments,

				emailSender,
				emailReplyTo,
				smtpHost,
				smtpPort,
				smtpUser,
				smtpPassword,

				splitStorageAllowed,
				partialPickupAllowed,
				recipientCollectionEnabled,

				gracePeriod,

				nfcCardLearningEnabled,
				photoInboxEnabled,
				checkAfterPickupRequired,
				deliveryCreationFromCodeEnabled,
				deliveryCreationForDepartmentEnabled,

				reminderHours,
				retentionDays,
				retentionDaysCreated,
				maxStorageTime,
				storageTimeConsidersWeekends, 
				scheduleAccessCodeRegenerationEnabled,

				recipientRequired,

				printViewConfig: PrintViewConfig.toJSON(printViewConfig),
				printViewConfigLarge: PrintViewConfig.toJSON(printViewConfigLarge),

				csvColumnMapping,
				csvCubeMapping,
				csvFirstLineIgnored,
				csvCharset,

				mapToManualHandoverEnabled,
				manualHandoverOptions,

				photoEnabled,
				ocrEnabled,
				recipientPickupKeyExposed,
				pickupCodeExposed,
				recipientsNotifyingSpecificationRequired,

				deliveryConfig,
				returnDeliveryConfig,
				useExistingBox,

				faqHelpUrl,

				ocrWidth,
				ocrHeight,

				autoTaggingEnabled,
			};
			try {
				let newTenant = await TenantUserProvider.updateSettings(tenantId, request);
				setPrintViewConfig(PrintViewConfig.merge(newTenant.printViewConfig));
				setPrintViewConfigLarge(PrintViewConfig.merge(newTenant.printViewConfigLarge));
				await SitesProvider.put(tenantId, sites).then(setSites);
				setDirty(false);
				reloadPage();
			} catch (err) {
				console.error("failed to update tenant", err);
			}
			setLoading(false);
		}
	}

	const reloadPage = () => {
		loadSettings();
		// window.location.replace(`/${tenantId}/settings`);
	};

	const missingTemplates = Object.values(TemplateType);

	const addNewTemplate = useCallback((type: TemplateType) => {
		setAddTemplate(false);
		const newTemplate: NotificationTemplate = {
			id: -1,
			center_id: tenant?.centerId,
			templateType: type,
			emailSubject: "",
			emailTemplate: "",
			smsTemplate: "",
			siteId: undefined,
		};
		const next = [...notificationTemplates, newTemplate];
		setNotificationTemplates(next);
		setEditingTemplate(next.length - 1);
	}, [notificationTemplates]);

	const updateTemplate = useCallback((template: NotificationTemplate) => {
		if (editingTemplate != undefined) {
			const copy = [...notificationTemplates];
			copy[editingTemplate] = template;
			setEditingTemplate(undefined);
			setNotificationTemplates(copy);
			saveNotificationTemplate(template);
		}
	}, [notificationTemplates, editingTemplate]);

	const deleteTemplate = useCallback((template: NotificationTemplate) => {
		deleteNotificationTemplate(template);
		setEditingTemplate(undefined);
		setNotificationTemplates(prev =>
			prev.filter((t) => {
				return t.siteId != template.siteId || t.templateType != template.templateType;
			})
		);
	}, []);

	async function saveNotificationTemplate(request: NotificationTemplate) {
		setLoading(true);
		try {
			if (request.id && request.id > 0) {
				await TenantTemplateProvider.updateTemplate(tenantId, request);
				setStateSnackbarMessage({
					text: t("settings.snackbarmessages.updateTemplateSuccess"),
					severity: "success",
				});
			} else {
				await TenantTemplateProvider.addTemplate(tenantId, request);
				setStateSnackbarMessage({text: t("settings.snackbarmessages.addTemplateSuccess"), severity: "success"});
				reloadPage();
			}
		} catch (err) {
			setStateSnackbarMessage({text: t("settings.snackbarmessages.updateTemplateError"), severity: "error"});
			console.error("failed to update notification template", err);
		}
		setLoading(false);
	}

	async function deleteNotificationTemplate(request: NotificationTemplate) {
		setLoading(true);
		try {
			if (request.id && request.id > 0) {
				await TenantTemplateProvider.deleteTemplate(tenantId, request);
				setStateSnackbarMessage({
					text: t("settings.snackbarmessages.deleteTemplateSuccess"),
					severity: "success",
				});
			}
		} catch (err) {
			setStateSnackbarMessage({text: t("settings.snackbarmessages.deleteTemplateError"), severity: "error"});
			console.error("failed to update notification template", err);
		}
		setLoading(false);
	}

	const addNewFragment = useCallback((tag: string) => {
		setAddTagFragment(false);
		const newTagFragment: TagFragment = {
			tag: tag,
			severity: TagFragmentSeverity.Info,
			title: "",
			text: "",
		};
		const next = [...tagFragments, newTagFragment];
		setTagFragments(next);
		setEditingTagFragment(next.length - 1);
	}, [tagFragments]);

	const updateTagFragment = useCallback((tagFragment: TagFragment) => {
		if (editingTagFragment != undefined) {
			const copy = [...tagFragments];
			copy[editingTagFragment] = tagFragment;
			setEditingTagFragment(undefined);
			setTagFragments(copy);
			setDirty(true);
		}
	}, [tagFragments, editingTagFragment]);

	const deleteTagFragment = useCallback((tag: string) => {
		const copy = tagFragments.filter((t) => t.tag != tag);
		setEditingTagFragment(undefined);
		setTagFragments(copy);
		setDirty(true);
	}, [tagFragments]);

	function handleBarcodeHeightChanged(height: number | null) {
		printViewConfig.barcode.height = height || 0;
		setPrintViewConfig({...printViewConfig});
		setDirty(true);
	}

	function handleBarcodeWidthChanged(width: number | null) {
		printViewConfig.barcode.width = width || 0;
		setPrintViewConfig({...printViewConfig});
		setDirty(true);
	}

	function handleBarcodeHeightLargeChanged(height: number | null) {
		printViewConfigLarge.barcode.height = height;
		setPrintViewConfig({...printViewConfigLarge});
		setDirty(true);
	}

	function handleBarcodeWidthLargeChanged(width: number | null) {
		printViewConfigLarge.barcode.width = width;
		setPrintViewConfig({...printViewConfigLarge});
		setDirty(true);
	}

	function handleBarcodeTypeChanged(checked: boolean) {
		printViewConfig.barcode.type = checked ? "QRCode" : null;
		setPrintViewConfig({...printViewConfig});
		setDirty(true);
	}

	function handleBarcodeTypeLargeChanged(checked: boolean) {
		printViewConfigLarge.barcode.type = checked ? "QRCode" : null;
		setPrintViewConfig({...printViewConfigLarge});
		setDirty(true);
	}

	function handlePrintableChanged(checked: boolean, i: number) {
		let newP = [...printViewConfig.printable];
		newP[i].print = checked;
		setPrintViewConfig({...printViewConfig});
		setDirty(true);
	}

	function handlePrintableLargeChanged(checked: boolean, i: number) {
		let newP = [...printViewConfigLarge.printable];
		newP[i].print = checked;
		setPrintViewConfigLarge({...printViewConfigLarge});
		setDirty(true);
	}

	function handleSiteChange(sites_: Site[]) {
		setSites(sites_);
		setDirty(true);
	}

	function handleCsvMappingsChanged(
		csvColumnMapping: CsvColumnMapping[],
		cubeMapping: CsvCubeMapping[],
		ignoreFirstLine: boolean,
		charset: string,
	) {
		setCsvColumnMapping(csvColumnMapping);
		setCsvCubeMapping(cubeMapping);
		setCsvFirstLineIgnored(ignoreFirstLine);
		setCsvCharset(charset);
		setDirty(true);
	}

	function handleDeliveryConfigChange(config: DeliveryConfig) {
		setDeliveryConfig(config);
		setDirty(true);
	}

	function handleReturnDeliveryConfigChange(config: ReturnDeliveryConfig) {
		setReturnDeliveryConfig(config);
		setDirty(true);
	}

	function sortNotifications(not: NotificationTemplate[]) {
		return not.sort((n1, n2) => {
			let res = n1.templateType.localeCompare(n2.templateType);
			if (res == 0) {
				res = (n1.siteId || "").localeCompare(n2.siteId || "");
			}
			return res;
		});
	}

	if (!tenant) {
		return (
			<ContainerLayout>
				<Grid container spacing={gs}>
					<Grid item xs={12}>
						<Loading />
					</Grid>
				</Grid>
			</ContainerLayout>
		);
	}

	return (
		<Fragment>
			<Grid container spacing={gs}>
				{isBreadcrumbVisible
					&& (
						<Grid item xs={12}>
							<Breadcrumbs>
								<BreadcrumbRouterLink to={`/${tenantId}/settings`}>
									{t("settings.plural")}
								</BreadcrumbRouterLink>
								<BreadcrumbItem>
									{t("settings.tenantSettings", {name: tenant?.name || " ..."})}
								</BreadcrumbItem>
							</Breadcrumbs>
						</Grid>
					)}
				{isBreadcrumbVisible
					&& (
						<Grid item xs={12}>
							<Typography variant="h2">
								{t("settings.tenantSettings", {name: tenant?.name || " ..."})}
							</Typography>
						</Grid>
					)}
				{refreshing && <Loading />}
				{!refreshing
					&& (
						<React.Fragment>
							<Grid item xs={12}>
								<Tabs
									value={tab}
									onChange={(e, tab) => setTab(tab)}
									items={[
										{label: t("settings.tabs.overview"), value: "overview"},
										{label: t("settings.tabs.sites"), value: "sites"},
										{label: t("settings.tabs.features"), value: "features"},
										{label: t("settings.tabs.notifications"), value: "notifications"},
										{label: t("settings.tabs.print_settings"), value: "print_settings"},
										{label: t("settings.tabs.csv_settings"), value: "csv_settings"},
									]}
								/>
								{tab == "overview" && (
									<BoxedPaper loading={loading}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<SimpleTextInput
													label={t("tenants.singular")}
													value={name}
													onChange={setName}
												/>
											</Grid>
											<Grid item sm={6} xs={12}>
												<SimpleTextInput
													label={t("tenants.email")}
													value={email}
													onChange={setEmail}
													type="email"
												/>
											</Grid>
											<Grid item sm={6} xs={12}>
												<SimpleTextInput
													label={t("tenants.phone")}
													value={phone}
													onChange={setPhone}
													type="phone"
												/>
											</Grid>

											<Grid item xs={12}>
												<Grid container spacing={gs}>
													<Grid item xs={12}>
														<HeaderWithHelpKey
															title={t("tenants.faqHelpUrl")}
															helpPage="Tenant_Settings"
															helpAnchor="FAQ_Help_Url"
															language=""
														/>
													</Grid>
													<Grid item xs={12}>
														<SimpleTextInput
															label={t("tenants.url")}
															value={faqHelpUrl}
															onChange={setFaqHelpUrl}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</BoxedPaper>
								)}

								{tab == "sites" && (
									<BoxedPaper loading={loading}>
										<Grid container spacing={gs}>
											{(sites && sites.length > 0) && (
												<Grid item xs={12}>
													<Sites
														sites={sites}
														notificationTemplates={notificationTemplates}
														onChange={s => handleSiteChange(s)}
													/>
												</Grid>
											)}
										</Grid>
									</BoxedPaper>
								)}

								{tab == "features" && (
									<BoxedPaper loading={loading}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<HeaderWithHelpKey
													title={t("tenants.behavior")}
													helpPage="Tenant_Settings"
													helpAnchor="Behavior"
													language=""
												/>
											</Grid>
											<Grid item md={3} sm={6} xs={12}>
												<NumberField
													label={t("tenants.retentionDays")}
													numberValue={retentionDays}
													numberFormat={Intl.NumberFormat()}
													onChangeNumber={v => setRetentionDays(v ?? 0)}
													fullWidth
													required
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<NumberField
													label={t("tenants.retentionDaysCreated")}
													numberValue={retentionDaysCreated}
													numberFormat={Intl.NumberFormat()}
													onChangeNumber={v => setRetentionDaysCreated(v ?? 0)}
													fullWidth
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<NumberField
													label={t("tenants.storageTimeMax")}
													numberValue={maxStorageTime}
													numberFormat={Intl.NumberFormat()}
													onChangeNumber={v => setMaxStorageTime(v ?? 0)}
													fullWidth
													required
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
											<SimpleCheckBox
													label={t("tenants.storageTimeConsidersWeekends")}
													checked={storageTimeConsidersWeekends} 
													onChange={setStorageTimeConsidersWeekends}
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<NumberField
													label={t("tenants.gracePeriod")}
													numberValue={gracePeriod}
													numberFormat={Intl.NumberFormat()}
													onChangeNumber={v => setGracePeriod(v ?? 0)}
													fullWidth
													required
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<RecipientRequiredInput
													label={t("tenants.recipientRequired")}
													value={recipientRequired}
													onChange={setRecipientRequired}
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<TextField
													variant="outlined"
													select
													fullWidth
													label={t("tenants.useExistingBox")}
													value={useExistingBox}
													onChange={(e) => setUseExistingBox(e.target.value as any)}
												>
													{Object.keys(UseExistingBox).map((s) => (
														<MenuItem key={s} value={s}>
															{e("tenants.useExistingBoxes", s)}
														</MenuItem>
													))}
												</TextField>
											</Grid>

											<Grid item xs={4}>
												<TenantManualHandoverOptionsInput
													label={t("tenants.manualHandoverOption")}
													value={manualHandoverOptions}
													onChange={setManualHandoverOptions}
												/>
											</Grid>

											<Grid item xs={12}>
												<HeaderWithHelpKey
													title={t("tenants.ocr")}
													helpPage="Tenant_Settings"
													helpAnchor="Ocr"
													language={language}
												/>
											</Grid>

											<Grid item xs={12}>
												<SimpleCheckBox
													label={t("tenants.enablePhoto")}
													checked={photoEnabled}
													onChange={setPhotoEnabled}
												/>
											</Grid>

											<Grid item xs={12}>
												<SimpleCheckBox
													label={t("tenants.disableOcr")}
													checked={ocrEnabled}
													disabled={!photoEnabled}
													onChange={setOcrEnabled}
												/>
											</Grid>
											<Grid item xs={4}>
												<NumberField
													label={t("tenants.ocrWidth")}
													numberFormat={IntegerNumberFormat}
													disabled={!ocrEnabled}
													numberValue={ocrWidth}
													onChangeNumber={setOcrWidth}
													fullWidth
												/>
											</Grid>
											<Grid item xs={4}>
												<NumberField
													label={t("tenants.ocrHeight")}
													numberFormat={IntegerNumberFormat}
													disabled={!ocrEnabled}
													numberValue={ocrHeight}
													onChangeNumber={setOcrHeight}
													fullWidth
												/>
											</Grid>

											<Hidden xsDown>
												<Grid item md={4} sm={6}></Grid>
											</Hidden>

											<Grid item xs={12}>
												<HeaderWithHelpKey
													title={t("tenants.features")}
													helpPage="Tenant_Settings"
													helpAnchor="Features"
													language=""
												/>
											</Grid>

											<Grid item md={4} sm={6} xs={12}>
												<SimpleCheckBox
													label={t("tenants.allowSplitStorage")}
													checked={splitStorageAllowed}
													onChange={setSplitStorageAllowed}
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<SimpleCheckBox
													label={t("tenants.allowPartialPickup")}
													checked={partialPickupAllowed}
													onChange={setPartialPickupAllowed}
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<SimpleCheckBox
													label={t("tenants.nfcCardLearning")}
													checked={nfcCardLearningEnabled}
													onChange={setNfcCardLearningEnabled}
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<SimpleCheckBox
													label={t("tenants.photoInbox")}
													checked={photoInboxEnabled}
													onChange={setPhotoInboxEnabled}
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<SimpleCheckBox
													label={t("tenants.checkAfterPickup")}
													checked={checkAfterPickupRequired}
													onChange={setCheckAfterPickupRequired}
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<SimpleCheckBox
													label={t("tenants.createDeliveryFromCode")}
													checked={deliveryCreationFromCodeEnabled}
													onChange={setDeliveryCreationFromCodeEnabled}
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<SimpleCheckBox
													label={t("tenants.scheduleRegenerateAccessCode")}
													checked={scheduleAccessCodeRegenerationEnabled}
													onChange={setScheduleAccessCodeRegenerationEnabled}
												/>
											</Grid>

											<Grid item md={4} sm={6} xs={12}>
												<SimpleCheckBox
													label={t("tenants.enabledRecipientCollection")}
													checked={recipientCollectionEnabled}
													onChange={setRecipientCollectionEnabled}
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<SimpleCheckBox
													label={t("tenants.displayPickupCode")}
													checked={pickupCodeExposed}
													onChange={setPickupCodeExposed}
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<SimpleCheckBox
													label={t("tenants.createDeliveryForDepartment")}
													checked={deliveryCreationForDepartmentEnabled}
													onChange={setDeliveryCreationForDepartmentEnabled}
												/>
											</Grid>

											<Grid item xs={4}>
												<SimpleCheckBox
													label={t("tenants.showRecipientPickupKey")}
													checked={recipientPickupKeyExposed}
													onChange={setRecipientPickupKeyExposed}
												/>
											</Grid>
											<Grid item xs={4}>
												<SimpleCheckBox
													label={t("tenants.specifyNotifyRecipients")}
													checked={recipientsNotifyingSpecificationRequired}
													onChange={setRecipientsNotifyingSpecificationRequired}
												/>
											</Grid>
											<Grid item xs={4}>
												<SimpleCheckBox
													label={t("tenants.autoTagging")}
													checked={autoTaggingEnabled}
													onChange={setAutoTaggingEnabled}
												/>
											</Grid>
											<Grid item xs={12}>
												<TenantDeliveryConfigSetting
													config={deliveryConfig}
													onChange={handleDeliveryConfigChange}
												/>
											</Grid>
											<Grid item xs={12}>
												<TenantReturnDeliveryConfigSetting
													config={returnDeliveryConfig}
													onChange={handleReturnDeliveryConfigChange}
												/>
											</Grid>
										</Grid>
									</BoxedPaper>
								)}

								{tab == "notifications" && (
									<BoxedPaper loading={loading}>
										<Grid container spacing={gs}>
											<Grid item sm={4} xs={12}>
												<LocalesSelection
													label={t("tenants.locales.plural")}
													helperText={t("tenants.locales.helperText")}
													values={locales.split(",").map((v) => v.trim()).filter((v) =>
														Boolean(v)
													)}
													onChange={(v) => setLocales(v.join(","))}
												/>
											</Grid>
											<Grid item sm={8} xs={12} />
											<Grid item md={4} sm={5} xs={12}>
												<TenantNotificationSettingInput
													label={t("tenants.notifications.title")}
													value={notifications}
													onChange={setNotifications}
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<NumberField
													label={t("tenants.reminderHours")}
													numberValue={reminderHours}
													onChangeNumber={v => setReminderHours(v ?? 0)}
													numberFormat={Intl.NumberFormat()}
													fullWidth
													required
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<SimpleTextInput
													label={t("tenants.emailSender")}
													value={emailSender}
													onChange={setEmailSender}
													type="email"
												/>
											</Grid>
											<Grid item md={4} sm={6} xs={12}>
												<SimpleTextInput
													label={t("tenants.emailReplyTo")}
													value={emailReplyTo}
													onChange={setEmailReplyTo}
													type="email"
												/>
											</Grid>
											<Grid item xs={12}>
												<Divider />
											</Grid>

											<Grid item xs={12}>
												<Grid container spacing={gs}>
													<Grid item style={{flexGrow: 1}}>
														<HeaderWithHelpKey
															title={t("tenants.customTemplates")}
															helpPage="Tenant_Settings"
															helpAnchor="Custom_Templates"
															language=""
														/>
													</Grid>
													<Grid item>
														{(notificationTemplates
															&& notificationTemplates.length > 0)
															&& (
																<Button
																	variant="outlined"
																	disabled={missingTemplates.length == 0}
																	onClick={() =>
																		navigate(
																			`/${tenant.centerId}/notifications/test`,
																		)}
																>
																	{t("tenants.testCustomTemplates")}
																</Button>
															)}

														<Button
															variant="outlined"
															style={{marginLeft: 16}}
															disabled={missingTemplates.length == 0}
															onClick={() => {
																setAddTemplate(true);
																setIsAddingTemplate(true);
															}}
														>
															{t("tenants.addTemplate.title")}
														</Button>
														<AddTemplateDialog
															options={missingTemplates}
															open={addTemplate}
															onClose={() => setAddTemplate(false)}
															onSelect={addNewTemplate}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Table size="small">
													<TableHead>
														<TableRow>
															<TableCell>{t("tenants.templateType")}</TableCell>
															<TableCell>{t("tenants.emailSubjectTemplate")}</TableCell>
															<TableCell>{t("tenants.smsTemplate")}</TableCell>
															<TableCell>{t("tenants.templateForSite")}</TableCell>
															<TableCell>{t("tenants.locales.singular")}</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{notificationTemplates.map((tR, index) => (
															<TableRow
																key={index}
																hover
																onClick={() => {
																	setEditingTemplate(index);
																	setIsAddingTemplate(false);
																}}
															>
																<TableCell className={classes.nonBreak}>
																	{e("tenants.templateTypes", tR.templateType)}
																</TableCell>
																<TableCell title={tR.emailSubject}>
																	{shorten(tR.emailSubject, 50)}
																</TableCell>
																<TableCell title={tR.smsTemplate}>
																	{shorten(tR.smsTemplate, 50)}
																</TableCell>
																<TableCell title={tR.siteId}>
																	{shorten(
																		tR?.siteId
																			? sites.find(s => s.siteId == tR.siteId)
																				?.name
																				?? ""
																			: t("tenants.templateForAllSite"),
																		50,
																	)}
																</TableCell>
																<TableCell>
																	{tR.locale}
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
												{editingTemplate != undefined && (
													<EditTemplateDialog
														open={editingTemplate != undefined}
														template={notificationTemplates[editingTemplate]}
														onCancel={() => setEditingTemplate(undefined)}
														existingTemplates={notificationTemplates}
														onConfirm={updateTemplate}
														onDelete={deleteTemplate}
														adding={isAddingTemplate}
													/>
												)}
											</Grid>
											<Grid item xs={12}>
												<Divider />
											</Grid>
											<Grid item xs={12}>
												<Grid container spacing={gs}>
													<Grid item style={{flexGrow: 1}}>
														<HeaderWithHelpKey
															title={t("tenants.tagFragments.plural")}
															helpPage="Tenant_Settings"
															helpAnchor="Tag_Fragments"
															language=""
														/>
													</Grid>
													<Grid item>
														<Button
															variant="outlined"
															onClick={() => setAddTagFragment(true)}
														>
															{t("tenants.tagFragments.add")}
														</Button>
														<AddTagFragmentDialog
															open={addTagFragment}
															onClose={() => setAddTagFragment(false)}
															onAdd={addNewFragment}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Table size="small">
													<TableHead>
														<TableRow>
															<TableCell>{t("tenants.tagFragments.tag")}</TableCell>
															<TableCell>{t("tenants.tagFragments.severity")}</TableCell>
															<TableCell>{t("tenants.tagFragments.title")}</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{tagFragments.map((t, index) => (
															<TableRow
																key={t.tag}
																hover
																onClick={() => setEditingTagFragment(index)}
															>
																<TableCell>{t.tag}</TableCell>
																<TableCell
																	className={classes.nonBreak}
																>
																	{e("tenants.tagFragments.severities", t.severity)}
																</TableCell>
																<TableCell
																	title={t.text}
																>
																	{shorten(t.title || t.text, 50)}
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
												{editingTagFragment != undefined && (
													<EditTagFragmentDialog
														open={editingTagFragment != undefined}
														tagFragment={tagFragments[editingTagFragment]}
														onCancel={() => setEditingTagFragment(undefined)}
														onConfirm={updateTagFragment}
														onDelete={deleteTagFragment}
													/>
												)}
											</Grid>
											<Grid item xs={12}>
												<Divider />
											</Grid>
											<Grid item xs={12} sm={6} md={8}>
												<Grid container spacing={gs}>
													<Grid item xs={12}>
														<HeaderWithHelpKey
															title={t("tenants.smtpServer")}
															helpPage="Tenant_Settings"
															helpAnchor="SMTP_Server"
															language=""
														/>
													</Grid>
													<Grid item md={4} sm={6} xs={12}>
														<SimpleTextInput
															label={t("tenants.smtpServerSettings.host")}
															value={smtpHost}
															onChange={setSmtpHost}
														/>
													</Grid>
													<Grid item md={4} sm={6} xs={12}>
														<NumberField
															label={t("tenants.smtpServerSettings.port")}
															numberValue={smtpPort}
															onChangeNumber={setSmtpPort}
															numberFormat={Intl.NumberFormat()}
															fullWidth
														/>
													</Grid>
													<Grid item md={4} sm={6} xs={12}>
														<SimpleTextInput
															label={t("tenants.smtpServerSettings.user")}
															value={smtpUser}
															onChange={setSmtpUser}
														/>
													</Grid>
													<Grid item md={4} sm={6} xs={12}>
														<SimpleTextInput
															label={t("tenants.smtpServerSettings.password")}
															value={smtpPassword}
															onChange={setSmtpPassword}
															type="password"
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} sm={6} md={4}>
												<OAuth2EmailSenderSetting oauth2={tenant.oauth2 ?? {}} />
											</Grid>
										</Grid>
									</BoxedPaper>
								)}

								{tab == "system_settings" && (
									<BoxedPaper loading={loading}>
										<Grid container spacing={gs}>
										</Grid>
									</BoxedPaper>
								)}

								{tab == "print_settings" && (
									<BoxedPaper loading={loading}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<Grid container spacing={gs}>
													<Grid item xs={12}>
														<HeaderWithHelpKey
															title={t("tenants.printView")}
															helpPage="Tenant_Settings"
															helpAnchor="Print_View"
															language=""
														/>
													</Grid>
													<Grid item xs={4}>
														<NumberField
															label={t("tenants.barcodeHeight")}
															numberValue={printViewConfig.barcode.height}
															onChangeNumber={handleBarcodeHeightChanged}
															numberFormat={Intl.NumberFormat()}
															fullWidth
														/>
													</Grid>
													<Grid item xs={4}>
														<NumberField
															label={t("tenants.barcodeWidth")}
															numberValue={printViewConfig.barcode.width}
															onChangeNumber={handleBarcodeWidthChanged}
															numberFormat={Intl.NumberFormat()}
															fullWidth
														/>
													</Grid>
													<Grid item xs={4}>
														<SimpleCheckBox
															label={t("tenants.barcodeTypeIsQRCode")}
															checked={printViewConfig.barcode.type == "QRCode"}
															onChange={event => handleBarcodeTypeChanged(event)}
														/>
													</Grid>
													{printViewConfig?.printable.map((p, i) => (
														<Grid item xs={3} key={i}>
															<SimpleCheckBox
																key={i}
																label={p.label ? t(p.label as any) : ""}
																checked={p.print}
																onChange={event => handlePrintableChanged(event, i)}
															/>
														</Grid>
													))}
													<Grid item>
													</Grid>
												</Grid>
											</Grid>

											<Grid item xs={12}>
												<Divider />
											</Grid>

											<Grid item xs={12}>
												<Grid container spacing={gs}>
													<Grid item xs={12}>
														<HeaderWithHelpKey
															title={t("tenants.printViewLarge")}
															helpPage="Tenant_Settings"
															helpAnchor="Print_View_Large"
															language=""
														/>
													</Grid>
													<Grid item xs={4}>
														<NumberField
															label={t("tenants.barcodeHeight")}
															numberValue={printViewConfigLarge.barcode.height}
															onChangeNumber={handleBarcodeHeightLargeChanged}
															numberFormat={Intl.NumberFormat()}
															fullWidth
														/>
													</Grid>
													<Grid item xs={4}>
														<NumberField
															label={t("tenants.barcodeWidth")}
															numberValue={printViewConfigLarge.barcode.width}
															onChangeNumber={handleBarcodeWidthLargeChanged}
															numberFormat={Intl.NumberFormat()}
															fullWidth
														/>
													</Grid>
													<Grid item xs={4}>
														<SimpleCheckBox
															label={t("tenants.barcodeTypeIsQRCode")}
															checked={printViewConfigLarge.barcode.type == "QRCode"}
															onChange={event => handleBarcodeTypeLargeChanged(event)}
														/>
													</Grid>
													{printViewConfigLarge?.printable.map((p, i) => (
														<Grid item xs={3} key={i}>
															<SimpleCheckBox
																key={i}
																label={p.label ? t(p.label as any) : ""}
																checked={p.print}
																onChange={event =>
																	handlePrintableLargeChanged(event, i)}
															/>
														</Grid>
													))}
													<Grid item>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</BoxedPaper>
								)}

								{tab == "csv_settings" && (
									<BoxedPaper loading={loading}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<TenantSettingsCsvImportEdit
													csvColumnMapping={csvColumnMapping}
													csvCubeMapping={csvCubeMapping}
													csvIgnoreFirstLine={csvFirstLineIgnored}
													csvCharset={csvCharset}
													callback={handleCsvMappingsChanged}
												/>
											</Grid>
											<Grid item xs={4}>
												<SimpleCheckBox
													label={t("tenants.mapToManualHandover")}
													checked={mapToManualHandoverEnabled}
													onChange={setMapToManualHandoverEnabled}
												/>
											</Grid>
										</Grid>
									</BoxedPaper>
								)}
							</Grid>

							<Grid item xs={12}>
								<Grid container spacing={gs}>
									<Grid item style={{flexGrow: 1}}>
										<Button
											variant="contained"
											color="primary"
											startIcon={<SaveIcon />}
											onClick={handleSave}
											disabled={!dirty || isNumbersInputNull}
										>
											{t("save")}
										</Button>
									</Grid>
									{isBreadcrumbVisible
										&& (
											<Grid item>
												<Button
													variant="contained"
													color="secondary"
													onClick={() => navigate(-1)}
												>
													{t("cancel")}
												</Button>
											</Grid>
										)}
								</Grid>
							</Grid>
						</React.Fragment>
					)}
			</Grid>
			<StateSnackbar
				message={stateSnackbarMessage}
				autoHideDuration={10000}
				onClose={() => {
					setStateSnackbarMessage(undefined);
				}}
			/>
		</Fragment>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	nonBreak: {
		whiteSpace: "nowrap",
	},
}));
