export enum TenantNotificationSetting {
	All = "All",
	Tenant = "Tenant",
	None = "None",
}

export enum TemplateType {
	StoredRecipient = "StoredRecipient",
	StoredMailroom = "StoredMailroom",
	ShipmentStoredMailroom = "ShipmentStoredMailroom",
	StoredAddRecipient = "StoredAddRecipient",
	StoredAddMailroom = "StoredAddMailroom",
	ReminderRecipient = "ReminderRecipient",
	ManualReminderRecipient = "ManualReminderRecipient",
	ReminderMailroom = "ReminderMailroom",
	OverdueRecipient = "OverdueRecipient",
	OverdueMailroom = "OverdueMailroom",
	PickedUpRecipient = "PickedUpRecipient",
	PickedUpMailroom = "PickedUpMailroom",
	CancelledRecipient = "CancelledRecipient",
	CancelledMailroom = "CancelledMailroom",
	ShippedSender = "ShippedSender",
	ShippedMailroom = "ShippedMailroom",
	ManualHandoverCreatedRecipient = "ManualHandoverCreatedRecipient",
	ManualHandoverCreatedMailroom = "ManualHandoverCreatedMailroom",
	ReturnDeliveryStoredMailroom = "ReturnDeliveryStoredMailroom",
	ReturnDeliveryStoredRecipient = "ReturnDeliveryStoredRecipient",
	ReturnDeliveryStoredRecipientReminder24hrs = "ReturnDeliveryStoredRecipientReminder24hrs",
	ReturnDeliveryReminderRecipient = "ReturnDeliveryReminderRecipient",
	ReturnDeliveryRetrievedMailroom = "ReturnDeliveryRetrievedMailroom",
	ReturnDeliveryRetrievedRecipient = "ReturnDeliveryRetrievedRecipient",
	ManualHandoverConfirmationRecipient = "ManualHandoverConfirmationRecipient",
	RedirectedRecipient = "RedirectedRecipient",
	RedirectedMailroom = "RedirectedMailroom",
	ManualNotification = "ManualNotification",
	MaintainanceMailroom = "MaintenanceMailroom",
}

export interface NotificationTemplate {
	id?: number;
	center_id?: string;
	templateType: TemplateType;
	emailSubject: string;
	emailTemplate: string;
	smsTemplate: string;
	siteId?: string;
	locale?: string;
}

export enum TagFragmentSeverity {
	Success = "Success",
	Info = "Info",
	Warning = "Warning",
	Error = "Error",
}

export interface TagFragment {
	tag: string;
	severity: TagFragmentSeverity;
	title: string;
	text: string;
}

export interface CsvColumnMapping {
	columnNumber: number;
	columnName: string;
	mandatory: boolean;
}

export interface CsvCubeMapping {
	pattern: string;
	cubeId: string;
}

export enum RecipientRequired {
	None = "None",
	Name = "Name",
	Email = "Email",
	Phone = "Phone",
	NameAndEmail = "NameAndEmail",
	NameAndPhone = "NameAndPhone",
	All = "All",
}

export enum TenantManualHandoverOptions {
	None = "None",
	Name = "Name",
	Signature = "Signature",
	NameAndSignature = "NameAndSignature",
	Recipient = "Recipient",
	RecipientAndSignature = "RecipientAndSignature",
	RecipientOrName = "RecipientOrName",
	RecipientOrNameAndSignature = "RecipientOrNameAndSignature",
	SignatureOrEmployeeNumber = "SignatureOrEmployeeNumber",
}

export enum UseExistingBox {
	Always = "Always",
	AskToAdd = "AskToAdd",
	Disabled = "Disabled",
}

export interface DeliveryFieldConfig {
	enabled: boolean;
	mandatory: boolean;
	verification?: boolean;
}

export interface DeliveryConfig {
	enabled: boolean;
	parameters?: {
		parcelId?: DeliveryFieldConfig;
		condition?: DeliveryFieldConfig;
		weight?: DeliveryFieldConfig;
		units?: DeliveryFieldConfig;
		maxStorageTime?: DeliveryFieldConfig;
		sender?: DeliveryFieldConfig;
		carrier?: DeliveryFieldConfig;
		description?: DeliveryFieldConfig;
		deliveryNumber?: DeliveryFieldConfig;
		orderNumber?: DeliveryFieldConfig;
		foreignId?: DeliveryFieldConfig;
		callbackUrl?: DeliveryFieldConfig;
		tags?: DeliveryFieldConfig;
	}
}

export interface ReturnDeliveryConfig {
	enabled: boolean;
	returnRequiresAuthorization: boolean;
	authorizationProviderHint?: string;
	parameters?: {
		deliveryNoteId?: DeliveryFieldConfig;
		returnReason?: DeliveryFieldConfig;
		email?: DeliveryFieldConfig;
		phone?: DeliveryFieldConfig;
		customerId?: DeliveryFieldConfig;
		positionNumber?: DeliveryFieldConfig;
		additionalInformation?: DeliveryFieldConfig;
	}
}

export interface Tenant {
	centerId: string;
	name: string;
	email?: string;
	phone?: string;
	locales?: string;
	notifications: TenantNotificationSetting;
	tagFragments: TagFragment[];

	emailSender?: string;
	emailReplyTo?: string;
	smtpHost?: string;
	smtpPort?: number;
	smtpUser?: string;
	smtpPassword?: string;

	splitStorageAllowed: boolean;
	partialPickupAllowed: boolean;
	recipientCollectionEnabled: boolean;

	gracePeriod: number;

	nfcCardLearningEnabled: boolean;
	photoInboxEnabled: boolean;
	checkAfterPickupRequired: boolean;
	deliveryCreationFromCodeEnabled: boolean;
	deliveryCreationForDepartmentEnabled: boolean;

	reminderHours: number;
	retentionDays: number;
	retentionDaysCreated: number;
	maxStorageTime: number;
	storageTimeConsidersWeekends: boolean;
	scheduleAccessCodeRegenerationEnabled: boolean;

	recipientRequired: RecipientRequired;

	printViewConfig: string;
	printViewConfigLarge: string;

	csvColumnMapping: CsvColumnMapping[];
	csvCubeMapping: CsvCubeMapping[];
	csvFirstLineIgnored: boolean;
	csvCharset?: string;

	mapToManualHandoverEnabled: boolean;
	manualHandoverOptions: TenantManualHandoverOptions;

	photoEnabled: boolean;
	ocrEnabled: boolean;
	recipientPickupKeyExposed: boolean;
	pickupCodeExposed: boolean;
	recipientsNotifyingSpecificationRequired: boolean;

	deliveryConfig?: DeliveryConfig;
	returnDeliveryConfig?: ReturnDeliveryConfig;
	useExistingBox: UseExistingBox;

	faqHelpUrl?: string;

	manualNotificationEnabled: boolean;

	ocrWidth?: number;
	ocrHeight?: number;

	autoTaggingEnabled: boolean;

	cubes: string[];

	oauth2?: TenantOAuth2;
}

export interface TenantOAuth2 {
	entraId?: string;
	clientId?: string;
	clientSecret?: string;
	smtpUser?: string;
}

export interface AppTenant {
	id: string;
	name: string;
}

export interface User {
	username: string;
	userType: UserType;
	email: string;
	firstName: string;
	lastName: string;
	fullname: string;
	accessCode: string;
	roles: string;
	receiveEmails: boolean;
	siteId: string;
}

export enum UserType {
	Unknown = "Unknown",
	Local = "Local",
	OAuth2 = "OAuth2",
}

export interface GuessUserTypeRequest {
	username: string;
}

export interface GuessUserTypeResponse extends GuessUserTypeRequest {
	userType: UserType;
	providerName?: string;
}

export interface CreateUserRequest {
	username: string;
	email: string;
	firstName: string;
	lastName: string;
	plaintextPassword: string;
	roles: string;
	receiveEmails: boolean;
	siteId: string;
}

export interface ChangePasswordRequest {
	currentPassword: string;
	nextPassword: string;
}

export interface TenantUpdateSettingsRequest {
	name: string;
	email: string;
	phone: string;
	locales: string;
	notifications: TenantNotificationSetting;
	tagFragments: TagFragment[];

	emailSender: string;
	emailReplyTo: string;
	smtpHost: string;
	smtpPort: number | null;
	smtpUser: string;
	smtpPassword: string;

	splitStorageAllowed: boolean;
	partialPickupAllowed: boolean;
	recipientCollectionEnabled: boolean;

	gracePeriod: number;

	nfcCardLearningEnabled: boolean;
	photoInboxEnabled: boolean;
	checkAfterPickupRequired: boolean;
	deliveryCreationFromCodeEnabled: boolean;
	deliveryCreationForDepartmentEnabled: boolean;

	reminderHours: number;
	retentionDays: number;
	retentionDaysCreated: number;
	maxStorageTime: number | null;
	storageTimeConsidersWeekends: boolean;

	scheduleAccessCodeRegenerationEnabled: boolean;

	recipientRequired: RecipientRequired;

	printViewConfig: string;
	printViewConfigLarge: string;

	csvColumnMapping: CsvColumnMapping[];
	csvCubeMapping: CsvCubeMapping[];
	csvFirstLineIgnored: boolean;
	csvCharset: string;

	mapToManualHandoverEnabled: boolean;
	manualHandoverOptions: TenantManualHandoverOptions;

	photoEnabled: boolean;
	ocrEnabled: boolean;
	recipientPickupKeyExposed: boolean;
	pickupCodeExposed: boolean;
	recipientsNotifyingSpecificationRequired: boolean;

	deliveryConfig: DeliveryConfig;
	returnDeliveryConfig: ReturnDeliveryConfig;
	useExistingBox: UseExistingBox;

	faqHelpUrl?: string;

	ocrWidth: number | null;
	ocrHeight: number | null;

	autoTaggingEnabled: boolean;
}

export function hasRole(user: User, role: string): boolean {
	const roles = (user.roles || "").split(",");
	for (let r of roles) {
		if (r === role) {
			return true;
		}
	}
	return false;
}

interface RequiredFields {
	name: boolean;
	email: boolean;
	phone: boolean;
}

export function getRequiredFields(tenant?: Tenant): RequiredFields {
	const requiredFields: RequiredFields = {
		name: false,
		email: false,
		phone: false,
	};
	if (!tenant) {
		return requiredFields;
	}
	switch (tenant.recipientRequired) {
		case RecipientRequired.Name:
			requiredFields.name = true;
			break;
		case RecipientRequired.Email:
			requiredFields.email = true;
			break;
		case RecipientRequired.Phone:
			requiredFields.phone = true;
			break;
		case RecipientRequired.NameAndEmail:
			requiredFields.name = true;
			requiredFields.email = true;
			break;
		case RecipientRequired.NameAndPhone:
			requiredFields.name = true;
			requiredFields.phone = true;
			break;
		case RecipientRequired.All:
			requiredFields.name = true;
			requiredFields.email = true;
			requiredFields.phone = true;
			break;
	}
	return requiredFields;
}
